import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/enviroments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/userlogin`;
    return this.http.post<any>(url, requestBody);
  }

  saveUserDetails(user: any): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  saveToken(token: string): void {
    localStorage.setItem('token', token);
  }

  signup(requestBody: any) {
    const url = `${environment.API_URL}/usersignup`;
    return this.http.post<any>(url, requestBody);
  }

  isAuthenticated(): Observable<boolean> {
    const url = `${environment.API_URL}/test`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any>(url, { headers }).pipe(
      map((response) => {
        return response.data.status === '202';
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  setLoggedIn(): void {
    localStorage.setItem('isLoggedIn', 'true');
  }

  setSignedUp(): void {
    localStorage.setItem('isSignedUp', 'true');
  }

  setModelCreated(): void {
    localStorage.setItem('isModelMake', 'true');
  }

  setChooseSubscription(): void {
    localStorage.setItem('isChooseSubscription', 'true');
  }

  //start training
  modelmade(payload: any): Observable<any> {
    const url = `${environment.API_URL}/modelmade`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(url, payload, { headers });
  }

  //agent details
  getAgentsDetail(): Observable<any> {
    const url = `${environment.API_URL}/useragents`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post<any>(url, null, { headers });
  }

  //agent creation
  AgentCreation(
    agentName: string,
    agentDesc: string,
    modelId: string,
    agent_language: string
  ): Observable<any> {
    const url = `${environment.API_URL}/createagent`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agent_name: agentName,
      agent_description: agentDesc,
      model_id: modelId,
      agent_language: agent_language,
    };
    return this.http.post<any>(url, body, { headers });
  }

  // update agent key
  updateAgentKey(agent_id: string): Observable<any> {
    const url = `${environment.API_URL}/updateagentkey`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agent_id: agent_id,
    };

    return this.http.post<any>(url, body, { headers });
  }

  // delete user agent
  deleteUserAgents(agent_id: string): Observable<any> {
    const url = `${environment.API_URL}/deleteuseragents`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agent_id: agent_id,
    };

    return this.http.post<any>(url, body, { headers });
  }

  //user model details
  getUserModels(): Observable<any> {
    const url = `${environment.API_URL}/usermodels`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post<any>(url, null, { headers });
  }

  //verify upload files
  verifyUploadFiles(filesArray: any): Observable<any> {
    const url = environment.VERIFY_UPLOAD_FILES_URL;
    const verifyData = filesArray.map(
      (file: { data_type: any; data_key: any }, index: number) => ({
        id: index + 1,
        data_type: file.data_type,
        data_key: file.data_key,
      })
    );
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.post<any>(url, verifyData);
  }

  // set tag that model is created
  setTag(tag: string): Observable<any> {
    const url = `${environment.API_URL}/settag`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const body = { tag: tag };

    return this.http.post(url, body, { headers });
  }

  //get tag that model is already created or not
  getTag(): Observable<string> {
    const url = `${environment.API_URL}/gettag`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(url, { headers }).pipe(
      map((response: any) => {
        return response.tag;
      })
    );
  }

  saveUserDetailAndTag(): void {
    const userData = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    localStorage.clear();
    if (userData !== null) {
      const user = JSON.parse(userData);
      this.saveUserDetails(user);
    }
    if (token !== null) {
      this.saveToken(token);
    }
    this.setSignedUp();
  }

  saveModelProcessingAndTag(): void {
    const userData = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    localStorage.clear();
    if (userData !== null) {
      const user = JSON.parse(userData);
      this.saveUserDetails(user);
    }
    if (token !== null) {
      this.saveToken(token);
    }
    this.setModelCreated();
    localStorage.setItem('isProcessed', 'true');
  }

  saveUserDetailAndChooseSubscription(): void {
    const userData = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    localStorage.clear();
    if (userData !== null) {
      const user = JSON.parse(userData);
      this.saveUserDetails(user);
    }
    if (token !== null) {
      this.saveToken(token);
    }
    this.setChooseSubscription();
  }

  //Chat --> API request with user query and object key
  getChatResponse(
    requestBody: any,
    userPlanID: number,
    modelURL: string
  ): Observable<any> {
    let url: string;
    if (
      userPlanID === 1 ||
      userPlanID === 2 ||
      userPlanID === 3 ||
      userPlanID === 6 ||
      userPlanID === 7 ||
      userPlanID === 8
    ) {
      url = `${environment.LANGCHAIN_CHATBOAT_URL_1}/response`;
    } else {
      url = modelURL;
    }

    return this.http.post<any>(url, requestBody);
  }

  //resend email to verify account
  resendverify(email: string): Observable<any> {
    const url = `${environment.API_URL}/resendverify`;
    const body = {
      email: email,
    };
    return this.http.post<any>(url, body);
  }

  //forgot password
  forgetpassword(email: string): Observable<any> {
    const url = `${environment.API_URL}/forgetpassword`;
    const body = {
      email: email,
    };
    return this.http.post<any>(url, body);
  }

  // create user subscription
  usersubscription(
    planId: number,
    stripeToken: string,
    amount: number,
    promo_code: string
  ): Observable<any> {
    const url = `${environment.API_URL}/usersubscription`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      planId: planId,
      stripeToken: stripeToken,
      ammount: amount,
      promo_code: promo_code,
    };

    return this.http.post<any>(url, body, { headers });
  }

  // create user subscription with trail
  usersubscriptionwithTrail(planId: number, amount: number): Observable<any> {
    const url = `${environment.API_URL}/usersubscription`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      planId: planId,
      ammount: amount,
    };

    return this.http.post<any>(url, body, { headers });
  }

  //get user details with plans
  getuserdetails(): Observable<any> {
    const url = `${environment.API_URL}/getuserdetails`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(url, { headers });
  }

  //end trail
  trialend(): Observable<any> {
    const url = `${environment.API_URL}/trialend`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post<any>(url, null, { headers });
  }

  //end subscription
  subscriptionend(): Observable<any> {
    const url = `${environment.API_URL}/subscriptionend`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post<any>(url, null, { headers });
  }

  //save user chat
  savechat(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/savechat`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post<any>(url, requestBody, { headers });
  }

  //at start get user conversation
  getconversation(agent_id: number): Observable<any> {
    const url = `${environment.API_URL}/getconversation`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = new HttpParams().set('agent_id', agent_id.toString());

    return this.http.get<any>(url, { headers, params });
  }

  //getplandetails and check no of agents allowed
  getplandetails(plan_id: any): Observable<any> {
    const url = `${environment.API_URL}/getplandetails`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = new HttpParams().set('plan_id', plan_id.toString());

    return this.http.get<any>(url, { headers, params });
  }

  //save agent message
  saveagentchat(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/saveagentchat`;

    return this.http.post<any>(url, requestBody);
  }

  //get agent conversation
  getagentconversation(api_key: any): Observable<any> {
    const url = `${environment.API_URL}/getagentconversation?api_key=${api_key}`;

    return this.http.get(url);
  }

  //check promo code
  validpromo(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/validpromo`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    const options = { headers, params: requestBody };

    return this.http.get<any>(url, options);
  }

  //save plugin
  saveplugin(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/saveplugin`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  //updtae plugin
  updateplugin(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/updateplugin`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  //get all user plugin
  alluserplugins(): Observable<any> {
    const url = `${environment.API_URL}/alluserplugins`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(url, { headers });
  }

  //get plugin by id
  getoneplugin(plugin_id: any): Observable<any> {
    const url = `${environment.API_URL}/getoneplugin`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = new HttpParams().set('plugin_id', plugin_id.toString());

    return this.http.get<any>(url, { headers, params });
  }

  //delete plugin by id
  deleteplugin(plugin_id: any): Observable<any> {
    const url = `${environment.API_URL}/deleteplugin`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = new HttpParams().set('plugin_id', plugin_id.toString());

    return this.http.get<any>(url, { headers, params });
  }

  // add whitelists url
  whitelisturls(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/whitelisturls`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  // get all whitelists url
  getwhitelists(agent_id: any): Observable<any> {
    const url = `${environment.API_URL}/getwhitelists`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = new HttpParams().set('agent_id', agent_id.toString());

    return this.http.get<any>(url, { headers, params });
  }

  //delete whitelist url
  deletewhitelist(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/deletewhitelist`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  // api for conditions
  createcondition(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/createcondition`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  returncondition(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/returncondition`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  returnuserconditions(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/returnuserconditions`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  deletecondition(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/deletecondition`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  updatecondition(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/updatecondition`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  //rephrase conditon description and what to send
  rephrase_condition(requestBody: any): Observable<any> {
    const url = `${environment.LANGCHAIN_CHATBOAT_URL}/rephrase_condition`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  rephrase_what_to_say(requestBody: any): Observable<any> {
    const url = `${environment.LANGCHAIN_CHATBOAT_URL}/rephrase_what_to_say`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  // return templates
  returntemplates(): Observable<any> {
    const url = `${environment.API_URL}/returntemplates`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<any>(url, { headers });
  }

  returnonetemplate(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/returnonetemplate`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  //connect agent with facebook
  agentfbconnect(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/agentfbconnect`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  removeagentfb(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/removeagentfb`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  getagentfbconnect(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/getagentfbconnect`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  getpages(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/getpages`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  //user info update
  changepassword(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/changepassword`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  userinforchange(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/userinforchange`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  //get all conversation of agent
  getagentallconversation(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/getagentallconversation`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    const params = new HttpParams().set(
      'agent_id',
      requestBody.agent_id.toString()
    );

    return this.http.get<any>(url, { headers, params });
  }

  //email integration
  addconnectedemails(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/email/addconnectedemails`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  getconnectedemails(): Observable<any> {
    const url = `${environment.API_URL}/email/getconnectedemails`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<any>(url, { headers });
  }

  updateconnectedemails(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/email/updateconnectedemails`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  toggleconnectedemails(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/email/toggleconnectedemails`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  deleteconnectedemails(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/email/deleteconnectedemails`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.post<any>(url, requestBody, { headers });
  }

  getconnectedinstagram(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/getconnectedinstagram`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  saveagentinstagraminfo(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/saveagentinstagraminfo`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  // wrokflow endpoint
  createworkflow(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/createworkflow`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  updateworkflow(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/updateworkflow`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  agentworkflows(): Observable<any> {
    const url = `${environment.API_URL}/agentworkflows`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, {}, { headers });
  }

  getworkflow(workflow_id: any): Observable<any> {
    const url = `${environment.API_URL}/getworkflow`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('workflow_id', workflow_id.toString());
    return this.http.post<any>(url, { headers, params });
  }

  deleteworkflow(workflow_id: any): Observable<any> {
    const url = `${environment.API_URL}/deleteworkflow`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const params = new HttpParams().set('workflow_id', workflow_id.toString());
    return this.http.post<any>(url, {}, { headers, params });
  }

  workflowtoggle(workflow_id: any): Observable<any> {
    const url = `${environment.API_URL}/workflowtoggle`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('workflow_id', workflow_id.toString());
    return this.http.post<any>(url, { headers, params });
  }

  workflows(requestBody: any) {
    const url = `${environment.LANGCHAIN_CHATBOAT_URL}/workflows`;
    return this.http.post<any>(url, requestBody);
  }

  getworkflowresponses(): Observable<any> {
    const url = `${environment.API_URL}/getworkflowresponses`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<any>(url, { headers });
  }
}
